<template>
    <div class="social-meeting-post-make" @scroll="onScroll">
        <StackRouterHeaderBar :title="$translate('MAKE')" :showTitle="showTitle" />
        <h2 class="title m-b-8" v-html="$translate('MAKE')" />
        <section v-if="!selectedType" class="section social-kind">
            <div class="m-b-28">
                <div class="select-title f-medium c-text c-black f-16">어떤 모임을 만드시겠어요?</div>
                <div class="f-12 f-medium c-facebook">직접 참가 신청자를 선택할 수 있어요!</div>
            </div>
            <SelectSocialType @changeSocialType="changeSocialType" />
        </section>
        <div v-show="selectedType">
            <section class="section brief-plan">
                <div class="type-desc">
                    <div class="badge">{{ socialTypeDesc.ko }}</div>
                    <div class="desc">{{ socialTypeDesc.desc }}</div>
                </div>
                <span
                    v-for="(item, idx) in selectedTypeCategory"
                    :key="item.id"
                    class="category-state"
                    :class="{ 'last-category': idx === selectedTypeCategory.length - 1 }"
                >
                    <p class="f-regular f-16 c-text-lighter" v-html="$translate(item.question)" />
                    <p
                        class="f-medium c-facebook f-16"
                        v-html="item.answer || $translate('PLEASE_SELECT')"
                        @click="showModalSelect(item.id)"
                    />
                </span>
                <div @click="kakaoMapModal" v-if="!meetPlace" class="badge">구체적 장소를 설정해주세요 (선택)</div>
                <div v-show="meetPlace">
                    <div class="map-container">
                        <div ref="map" class="map-img" />
                        <div class="placeinfo">
                            <div class="placename" v-html="meetPlace.place_name" />
                            <div class="address" v-html="meetPlace.address_name" />
                        </div>
                    </div>
                    <div @click="kakaoMapModal" class="badge m-t-12">수정하기</div>
                </div>
            </section>
            <section class="section explain-plan">
                <div class="explain-title m-b-32">
                    <p class="section-title m-b-12" v-html="$translate('TITLE')" />

                    <TextareaWithX
                        class="input-title"
                        v-model="title"
                        :placeholder="$translate('FILL_IN_TITLE')"
                        :from="`social_meeting`"
                        @focus="onFocus"
                        @blur="onBlur"
                        @input="onInput"
                    />
                </div>
                <div class="explain-content m-b-34">
                    <div class="flex-row flex-between">
                        <p class="section-title m-b-12" v-html="$translate('CONTENT')" />
                        <p class="section-title" :class="[content.length < 50 ? 'c-pink-deep' : 'c-facebook']">
                            글자 수: {{ content.length }}
                        </p>
                    </div>

                    <TextareaWithX
                        class="input-content"
                        v-model="content"
                        :placeholder="$translate('FILL_IN_CONTENT')"
                        :from="`social_meeting`"
                        @focus="onFocus"
                        @blur="onBlur"
                        @input="onInput"
                    />

                    <ul class="description flex m-t-8">
                        <li :key="idx" v-for="idx in 3">
                            <div class="flex-row" v-if="selectedType === 'friend' && idx === 1">
                                <p class="dot m-r-8" />
                                <p v-html="$translate(`SOCIAL_MEETING_POST_MAKE_DESCRIPTION_${idx}`)" />
                            </div>
                            <div class="flex-row" v-if="idx !== 1">
                                <p class="dot m-r-8" />
                                <p v-html="$translate(`SOCIAL_MEETING_POST_MAKE_DESCRIPTION_${idx}`)" />
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="explain-photo">
                    <PhotoInputArea
                        :from="this.selectedType"
                        :isOption="true"
                        @submitPhoto="updatePhoto"
                        @clearPhoto="clearPhoto"
                    />
                </div>
                <div v-if="selectedType === 'friend'" class="m-t-40">
                    <SocialMeetProfileSelect :isMale="isMale" @selectprofileStyle="submitprofileStyle" />
                </div>
            </section>
            <div class="social-meeting-manual">
                <div class="flex-row flex-between">
                    <p class="f-16 c-black f-regular">{{ $translate('SOCIAL_MEETING_MANUAL') }}</p>
                </div>

                <ul class="m-t-20">
                    <li class="manual" v-for="m in manual" :key="m">
                        <div class="dot" />
                        <p class="text" v-html="m" />
                    </li>
                </ul>
            </div>
            <BottomButton
                v-show="showButton"
                :label="$translate('MAKE')"
                :disabled="buttonDisabled"
                service="socialMeet"
                @click="submit"
            />
        </div>
    </div>
</template>
<script>
import SelectSocialType from '@/routes/social-meeting/components/SelectSocialType'
import PhotoInputArea from '@/components/app/PhotoInputArea'
import SocialMeetProfileSelect from '@/routes/social-meeting/components/SocialMeetProfileSelect'

import eventService from '@/services/event'
import userService from '@/services/user'
import { formatDate } from '@/filters'

export default {
    name: 'SocialMeetingPostMakePage',
    components: {
        SelectSocialType,
        PhotoInputArea,
        SocialMeetProfileSelect,
    },
    data: () => ({
        showTitle: false,
        categoryState: [],
        stateOptions: [],
        selectedType: '',
        eventRegions: '',
        eventRegionId: '',
        dates: [],
        title: '',
        content: '',
        customPhoto: {
            blob: null,
            url: null,
        },
        categoryId: null,
        socialTypeId: null,
        stateId: null,
        memberCnt: null,
        expiredAt: null,
        showButton: true,
        timer: null,
        isTyping: false,
        map: null,
        place: '',
        selectedPlace: {},
        // meetPlace: '',
        markers: [],
        profileStyle: 'mini',
        verifiedHost: false,
    }),
    mounted() {
        this.init()
        this.initDates()
    },
    beforeDestroy() {
        this.$store.commit('setMeetPlace', null)
    },
    watch: {
        isTyping: function (newVal) {
            if (!newVal) {
                this.showButton = false
            } else {
                this.showButton = true
            }
        },
        meetPlace: function (newVal) {
            this.map = ''
            setTimeout(() => {
                const mapContainer = this.$refs.map
                const center = new kakao.maps.LatLng(newVal.y, newVal.x)
                const mapOption = {
                    center: center,
                    level: 3,
                    disableDoubleClick: true,
                }
                this.map = new kakao.maps.Map(mapContainer, mapOption)

                var marker = new kakao.maps.Marker({
                    position: new kakao.maps.LatLng(newVal.y, newVal.x),
                })
                marker.setMap(this.map)
            }, 200)
        },
    },
    computed: {
        meetPlace() {
            return this.$store.getters.meetPlace
        },
        categoryOptions() {
            return this.$store.getters.categories.filter(c => c.category === `${this.selectedType}_meeting`)
        },
        buttonDisabled() {
            if (this.selectedType === 'group')
                return !(
                    this.categoryId &&
                    this.stateId &&
                    this.title &&
                    this.content.length >= 50 &&
                    this.memberCnt &&
                    this.expiredAt &&
                    this.customPhoto.blob
                )
            else if (this.selectedType === 'friend')
                return !(this.stateId && this.title && this.content.length >= 50 && this.memberCnt && this.expiredAt)
            else return false
        },
        socialTypeDesc() {
            if (this.selectedType === 'group') {
                return {
                    ko: '회원끼리',
                    desc: '밥 / 술 / 액티비티 등 모임을 열어보세요!',
                }
            } else {
                return {
                    ko: '친구끼리',
                    desc: '편하고 안전하게 친구와 함께 만나보세요!',
                }
            }
        },
        isMale() {
            const gender = (this.$store.getters.me || {}).gender

            return gender === 0
        },
        selectedTypeCategory() {
            if (this.selectedType === 'friend') {
                return this.categoryState.filter(category => category.id !== 'category')
            } else return this.categoryState
        },
        manual() {
            if (this.selectedType) {
                return [
                    this.$translate(`${this.selectedType.toUpperCase()}_MEETING_MANUAL_DESC1`),
                    this.$translate(`${this.selectedType.toUpperCase()}_MEETING_MANUAL_DESC2`),
                    this.$translate(`${this.selectedType.toUpperCase()}_MEETING_MANUAL_DESC3`),
                ]
            } else return []
        },
    },
    methods: {
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 52 // 52 is stackrouter header height
        },
        kakaoMapModal() {
            this.$stackRouter.push({
                name: 'KakaoMapPage',
            })
        },
        changeSocialType(type) {
            if (type === 'group') {
                if (!this.verifiedHost) {
                    this.$modal
                        .basic({
                            body: `승인된 호스트만 작성할 수 있어요`,
                            buttons: [
                                {
                                    label: 'CLOSE',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'HOW_TO_HOST',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                this.$nativeBridge.postMessage({
                                    action: 'openInAppBrowser',
                                    value: 'https://www.notion.so/7365dd9dac544d47b171f8886a97c359',
                                })
                            }
                        })
                    return
                }
            }
            this.selectedType = type
            this.categoryState = [
                {
                    id: this.selectedType,
                    question: '몇 명이 만날까요',
                    answer: null,
                },
                {
                    id: 'category',
                    question: '만나서 뭐 할까요',
                    answer: null,
                },
                {
                    id: 'when',
                    question: '언제 만날까요',
                    answer: null,
                },
                {
                    id: 'state',
                    question: '어디서 만날까요',
                    answer: null,
                },
            ]
        },
        updatePhoto(photo) {
            this.customPhoto = photo
        },
        clearPhoto() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
        },
        parseDate(date) {
            const today = this.$moment(date)
            const parsed = today.format('MM.DD')
            const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토']
            return `${parsed} (${dayOfWeek[today.day()]})`
        },
        initDates() {
            const res = []
            const today = new Date()
            for (let i = 0; i <= 29; i++) {
                const nextDay = !i ? today.setDate(today.getDate()) : today.setDate(today.getDate() + 1)
                let name
                if (i === 0 || i === 1) {
                    const badgeDay = ['오늘', '내일']
                    const badge = `<span class="f-normal"
                                        style="padding: 2px 10px; background: #f1f2f4;
                                        border-radius: 6px; margin-left: 8px; font-size: 12px"
                                    >${badgeDay[i]}
                                    </span>`
                    name = `${this.parseDate(nextDay)} ${badge}`
                } else {
                    name = this.parseDate(nextDay)
                }
                res.push({
                    name: name,
                    noBadge: this.parseDate(nextDay),
                    origin: formatDate(nextDay),
                })
            }
            this.dates = res
        },
        async init() {
            this.stateOptions = this.$store.getters.states
            this.eventRegions = this.$store.getters.eventRegions
            this.customPhoto = { blob: null, url: null }

            // 인증된 호스트인지 확인
            const { host } = await userService.hostCheck()
            this.verifiedHost = host ? host.verified : false
        },
        onClickPhoto() {
            this.$refs.imageUploader.click()
        },
        onClickClear() {
            this.customPhoto.blob = null
            this.customPhoto.url = null
        },
        onChangeImage(event) {
            const file = event.target.files[0]
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.customPhoto.url = URL.createObjectURL(croppedFile)
                    this.customPhoto.blob = croppedFile
                })
        },
        showModalSelect(id) {
            let modalOptions = []
            let selectedId
            if (id === 'category') {
                modalOptions = this.categoryOptions
                selectedId = this.categoryId
            } else if (id === 'state') {
                modalOptions = this.stateOptions
                selectedId = this.stateId
            } else if (id === 'group') {
                ;[3, 4, 5, 6, 7, 8, 9, 10].forEach((e, idx) => {
                    if (idx < 2) {
                        modalOptions.push({ id: e, name: `${e}명` })
                    } else {
                        const span = `<span style="color: #adacb0;">${e}명</span>`
                        modalOptions.push({
                            id: e,
                            name: span,
                            handler: () => this.$toast.error('정부 시책에 따라 최대 4인까지만 설정할 수 있어요'),
                        })
                    }
                })
                selectedId = this.memberCnt
            } else if (id === 'friend') {
                modalOptions = [
                    {
                        id: 4,
                        name: '2:2',
                    },
                    {
                        id: 6,
                        name: `<span style="color: #adacb0;">3:3</span>`,
                        handler: () => this.$toast.error('정부 시책에 따라 최대 4인까지만 설정할 수 있어요'),
                    },
                    {
                        id: 8,
                        name: `<span style="color: #adacb0;">4:4</span>`,
                        handler: () => this.$toast.error('정부 시책에 따라 최대 4인까지만 설정할 수 있어요'),
                    },
                ]
            } else if (id === 'when') {
                modalOptions = this.dates
                selectedId = this.expiredAt
            }

            this.$modal
                .custom({
                    component: 'ModalSelect',
                    options: {
                        title: id === 'friend' ? 'memberfriend' : id,
                        options: modalOptions,
                        from: 'social',
                        selectedId: selectedId,
                    },
                })
                .then(res => {
                    let answer
                    if (id === 'category') {
                        answer = res.title
                        this.categoryId = res.id
                    } else if (id === 'state') {
                        if ([1, 2].includes(res.id)) {
                            this.$modal
                                .custom({
                                    component: 'ModalSelect',
                                    options: {
                                        title: '상세 지역',
                                        options: this.eventRegions.filter(region => region.state_id === res.id),
                                    },
                                })
                                .then(res => {
                                    answer = `${res.state_id === 1 ? '서울' : '경기'}(${res.name})`
                                    this.eventRegionId = res.id
                                    this.stateId = res.state_id
                                    this.categoryState.find(item => item.id === id).answer = answer
                                })
                        } else {
                            answer = res.name
                            this.eventRegionId = this.eventRegions.find(region => region.state_id === res.id).id
                            this.stateId = res.id
                        }
                    } else if (id === 'group' || id === 'friend') {
                        answer = res.name
                        this.memberCnt = res.id
                    } else if (id === 'when') {
                        answer = res.noBadge
                        this.expiredAt = res.origin
                    }

                    this.categoryState.find(item => item.id === id).answer = answer
                })
        },
        async submit() {
            try {
                this.$loading(true)
                const place = this.meetPlace
                const payload = {
                    title: this.title,
                    content: this.content,
                    category_id:
                        this.selectedType === 'group'
                            ? this.categoryId
                            : this.categoryOptions.find(c => c.category === 'friend_meeting').id,
                    state_id: this.stateId,
                    event_region_id: this.eventRegionId,
                    member: this.memberCnt,
                    expire_at: this.expiredAt,
                    event_type: `${this.selectedType}_meeting`,
                    profile_style: this.selectedType === 'friend' ? this.profileStyle : 'social',

                    // 만남장소
                    place_name: place ? place.place_name : '',
                    place_address: place ? place.address_name : '',
                    latitude: place ? place.x : '',
                    longitude: place ? place.y : '',
                }

                if (this.customPhoto.blob) payload.photo = this.customPhoto.blob

                // vanilla pay
                const haveToPay = this.isMale && this.profileStyle === 'privacy'
                if (haveToPay) {
                    const { vanilla: currentVanilla } = this.$store.getters.badges
                    const { vanilla } = this.$store.getters.settings

                    if (currentVanilla < vanilla.today_meeting_create_privacy) {
                        // vanilla 부족 시 구매페이지 이동
                        const idx = await this.$modal.basic({
                            body: `바닐라 ${vanilla.participate_meeting - currentVanilla}개가 부족합니다.`,
                            buttons: [
                                {
                                    label: 'CLOSE',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'GO_TO_STORE',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1) this.$stackRouter.push({ name: 'InAppStorePage' })
                    } else {
                        // 바닐라 사용해 진행
                        const idx = await this.$modal.basic({
                            body: `바닐라 50개를 사용합니다.`,
                            buttons: [
                                {
                                    label: 'CLOSE',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1) {
                            await this.requestInteresting(payload)
                            await this.$store.dispatch('loadBadges')
                        }
                    }
                } else {
                    await this.requestInteresting(payload)
                }
            } catch (e) {
                const msg = e.data ? e.data.msg : '금지어를 입력하셨습니다.'
                this.$modal.basic({
                    body: msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'SocialSecondWriting_Click_CreatePostBtn',
                    },
                })
            }
        },
        async requestInteresting(payload) {
            try {
                this.$loading(true)
                const { msg } = await eventService.create(this.preparePayload(payload))
                await this.$store.dispatch('loadSocialMeetingPosts')
                this.$toast.success(msg)
                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
        preparePayload(payload) {
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                formData.append(key, value)
            })

            return formData
        },
        onFocus() {
            this.showButton = false
        },
        onBlur() {
            this.showButton = true
        },
        onInput() {
            this.showButton = false
            if (this.timer) {
                clearTimeout(this.timer)
                this.isTyping = false
            }
            this.timer = setTimeout(() => {
                this.isTyping = true
            }, 1500)
        },
        submitprofileStyle(profile) {
            if (this.profileStyle !== profile) this.profileStyle = profile
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .stack-router-header-bar {
    &.show-title {
        background-color: white;
        border-bottom: solid 1px $grey-03;
    }
}
.social-meeting-post-make {
    $section-side-padding: 16px;
    $bottom-button-height: 80px;

    padding-bottom: $bottom-button-height;
    line-height: normal;
    letter-spacing: -0.2px;

    .title {
        font-size: 24px;
        font-weight: 500;
        color: black;
        padding: 0 16px;
        @include f-medium;
    }

    .section {
        padding: 40px $section-side-padding;

        &.brief-plan,
        &.explain-plan {
            //padding-top: 0;
            border-bottom: 1px solid $grey-02;
        }

        .type-desc {
            display: flex;
            align-items: flex-end;
            margin-bottom: 60px;

            .badge {
                display: inline-block;
                background-color: $blue-facebook;
                color: white;
                padding: 5px 12px;
                border-radius: 8px 8px 8px 0;
                font-size: 15px;
                margin-right: 10px;
                @include f-medium;
            }
            .desc {
                font-size: 13px;
                @include f-medium;
            }
        }

        .badge {
            background: $grey-02;
            color: $grey-08;
            font-size: 14px;
            padding: 8px 65px;
            @include f-regular;
        }

        .category-state {
            margin-bottom: 32px;
            @include flex-row;
            @include flex-between;

            &.last-category {
                margin-bottom: 16px !important;
            }
        }

        .map-container {
            border-radius: 10px;
            border: 1px solid $grey-02;

            .map-img {
                width: 100%;
                height: 220px;
                border-radius: 10px 10px 0 0;
            }

            .placeinfo {
                padding: 14px;

                .placename {
                    color: black;
                    font-size: 16px;
                    margin-bottom: 4px;
                    @include f-medium;
                }
                .address {
                    color: $grey-08;
                    font-size: 13px;
                }
            }
        }

        ::v-deep .textarea-with-x {
            .material-icons {
                display: none;
            }
            &.social-focus {
                border: 1px solid $blue-facebook !important;
            }
        }

        hr {
            width: 100%;
            height: 1px;
            background: $grey-02;
            border: none;
        }

        &-title {
            font-size: 14px;
            font-weight: 500;
            color: $grey-08;

            @include f-medium;
        }

        &-desc {
            font-size: 12px;
            color: $grey-07;
        }

        .input-title {
            height: 48px;
            padding: 12px 16px;

            ::v-deep textarea {
                line-height: 22px;
            }
        }

        .input-content {
            height: 180px;
        }
    }

    .description {
        background-color: $grey-01;
        padding: 14px;
        font-size: 12px;
        border-radius: 10px;

        li {
            display: flex;
            color: black;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .dot {
            margin-top: 6px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $grey-07;

            @include flex-wrap;
        }
    }

    .social-meeting-manual {
        padding: 24px 16px;

        .manual {
            display: flex;
            margin-bottom: 8px;

            .dot {
                width: 4px;
                height: 4px;
                background-color: $grey-05;
                border-radius: 2px;
                margin-top: 7px;
                margin-right: 12px;
            }

            .text {
                font-size: 12px;
                color: $grey-09;

                ::v-deep span {
                    @include f-bold;
                }
            }
        }
    }

    .bottom-button {
        position: absolute;
    }
}
</style>
