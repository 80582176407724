<template>
    <div class="flex-row flex-between">
        <div class="social-kind-card" :class="{ selected: group.selected }" @click="changeSocialType(group.name)">
            <img v-if="group.selected" :src="require('@/assets/images/social-meeting/social_group_active.svg')" />
            <img v-else :src="require('@/assets/images/social-meeting/social_group_inactive.svg')" />

            <div class="title" :class="{ selected: group.selected }">회원끼리</div>
            <div class="content" :class="{ selected: group.selected }">
                밥 / 술 / 액티비티 등<br />모임을 열어보세요!
            </div>
        </div>
        <div class="social-kind-card" :class="{ selected: friend.selected }" @click="changeSocialType(friend.name)">
            <img v-if="friend.selected" :src="require('@/assets/images/social-meeting/social_friend_active.svg')" />
            <img v-else :src="require('@/assets/images/social-meeting/social_friend_inactive.svg')" />

            <div class="title" :class="{ selected: friend.selected }">친구끼리</div>
            <div class="content" :class="{ selected: friend.selected }">
                2:2 / 3:3 / 4:4로<br />친구와 함께 만나보세요!
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectSocialType',
    data: () => ({
        group: {
            name: 'group',
            ko: '회원끼리',
            selected: false,
        },
        friend: {
            name: 'friend',
            ko: '친구끼리',
            selected: false,
        },
    }),
    methods: {
        changeSocialType(type) {
            let category
            if (type === 'group') {
                this.group.selected = true
                this.friend.selected = false
                category = 'SocialFirstWriting_Click_GroupBtn'
            } else if (type === 'friend') {
                this.group.selected = false
                this.friend.selected = true
                category = 'SocialFirstWriting_Click_FriendsBtn'
            }

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: category,
                },
            })

            setTimeout(() => {
                this.$emit('changeSocialType', type)
            }, 300)
        },
    },
}
</script>

<style scoped lang="scss">
.social-kind-card {
    width: calc(100% / 2 - 6px);
    height: call(100% / 2);
    @include center;
    border: 1px solid $grey-03;
    border-radius: 12px;
    padding: 12px;
    flex-direction: column;

    &.selected {
        border-color: $blue-facebook;
    }

    .title {
        font-size: 15px;
        color: $grey-07;
        margin-top: 14px;
        margin-bottom: 6px;

        &.selected {
            color: $blue-facebook;
        }
    }

    .content {
        font-size: 12px;
        line-height: 1.42;
        color: $grey-05;
        text-align: center;
        @include f-medium;

        &.selected {
            color: $grey-08;
        }
    }
}
</style>
