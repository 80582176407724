<template>
    <div>
        <p class="f-medium section-title" v-html="`프로필 공개 타입`" />
        <p class="c-facebook f-12 f-medium m-b-12" v-html="`선택한 프로필 타입이 게시글에 표시됩니다`" />
        <div class="grid-col">
            <div class="profile-type" :class="{ selected: mini.selected }" @click="selectProfileType(mini.name)">
                <div class="default-photo">
                    <img class="profile-photo" :src="$store.getters.me.photos[0].url" alt="" />
                </div>
                <div class="profile-desc" :class="isMale ? 'm-b-6' : 'm-b-16'">
                    <span>{{ isMale ? '남' : '여' }}</span>
                    <span>{{ `(${$options.filters.asAge(myProfile.birthday)}),` }}</span>
                    <span> {{ myProfile.job_name }}</span>
                </div>
                <div v-show="isMale" class="howmuch" v-html="`무료`" />
                <div :class="{ selected: mini.selected }" class="profile-name" v-html="mini.ko" />
            </div>
            <div class="profile-type" :class="{ selected: privacy.selected }" @click="selectProfileType(privacy.name)">
                <div class="default-photo"><img class="private-photo" src="@/assets/images/default_profile.svg" /></div>
                <div class="profile-desc" :class="isMale ? 'm-b-6' : 'm-b-16'">
                    <span>{{ isMale ? '남' : '여' }} </span>
                    <span>{{ `(${$options.filters.blindAge($options.filters.asAge(myProfile.birthday))})` }}</span>
                </div>
                <div v-show="isMale" class="howmuch" v-html="howMuchVanilla" />
                <div :class="{ selected: privacy.selected }" class="profile-name" v-html="privacy.ko" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TodayMeetProfileSelect',
    props: {
        isMale: Boolean,
    },
    data: () => ({
        mini: {
            name: 'mini',
            ko: '미니 프로필',
            selected: true,
        },
        privacy: {
            name: 'privacy',
            ko: '비공개 프로필',
            selected: false,
        },
    }),
    computed: {
        myProfile() {
            return this.$store.getters.me.profile
        },
        howMuchVanilla() {
            if (this.isMale) return `바닐라 50개`
            else {
                return '무료'
            }
        },
    },
    methods: {
        selectProfileType(type) {
            if (type === 'mini') {
                this.mini.selected = true
                this.privacy.selected = false
                this.$emit('selectprofileStyle', type)
            } else if (type === 'privacy') {
                this.privacy.selected = true
                this.mini.selected = false
                this.$emit('selectprofileStyle', type)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.profile-type {
    border: 1px solid $grey-03;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px 0;

    &.selected {
        border-color: $blue-facebook;
    }

    .default-photo {
        height: 52px;
        width: 52px;
        padding: 5px;
        margin-bottom: 8px;
        border-radius: 20.8px;
        border: solid 1px $grey-03;
        background-color: $grey-01;
        @include center;

        .profile-photo {
            width: 52px;
            height: 52px;
            border-radius: 20.8px;
        }
        .private-photo {
            width: 20px;
            height: 20px;
        }
    }
    .profile-desc {
        font-size: 14px;

        &.female-margin {
            margin-bottom: 16px;
        }
    }
    .howmuch {
        color: $pink-deep;
        font-size: 12px;
        @include f-bold;
    }
    .profile-name {
        color: black;
        @include f-medium;

        &.selected {
            color: $blue-facebook;
        }
    }
}
</style>
